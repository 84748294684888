import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons'
import Image from 'next/image'
import React, { Fragment } from 'react'

import { CurrencyField } from '@/components/commons/DisplayData'
import { PictureCard } from '@/components/surfaces'
import { Basket as BasketType, BasketEnrollment as BasketEnrollmentType } from '@/types'

import ProductComplements from './ProductComplements'
import QuantityButton from './QuantityButton'

interface Props {
  basket: BasketType
  handleCleanBasket: (studentUid: string, studentName: string) => void
  handleMutate: () => void
}

export default function BasketLines({ basket, handleCleanBasket, handleMutate }: Props) {
  // Constants
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)
  const {
    enrollments,
    institutions = [],
    lines = [],
    products,
    sellers,
    showcases = [],
    students = []
  } = basket || {}
  const enrollmentBaskets: BasketEnrollmentType[] = enrollments?.map(
    ({ student, institution, showcase, uid }) => {
      return {
        uid,
        student: students?.find(item => item.uid === student),
        institution: institutions?.find(item => item.uid === institution),
        showcase: showcases?.find(item => item.uid === showcase),
        lines: lines?.filter(item => item.enrollment === uid)
      }
    }
  )

  if (Array.isArray(lines) && lines.length === 0) {
    return (
      <Center>
        <Image
          src="/images/placeholders/empty-cart.png"
          alt="Carrinho Vazio"
          layout="fixed"
          width={280}
          height={280}
        />
      </Center>
    )
  }

  return (
    <>
      {enrollmentBaskets?.map(({ institution, lines, showcase, student, uid }) => {
        const studentFullName = `${student?.firstName} ${student?.lastName}` || ''
        return (
          <Paper mb="sm" key={uid} withBorder>
            <Group p="xs" position="apart" noWrap>
              {student && (
                <>
                  <PictureCard
                    title={studentFullName}
                    subtitle={'comprando para'}
                    picture={student.picture}
                    subtitlePosition="before"
                  />
                  <Tooltip label="Remover todos itens do aluno">
                    {isSm ? (
                      <ActionIcon
                        color="red.6"
                        onClick={() => handleCleanBasket(student.uid, studentFullName)}>
                        <IconTrash />
                      </ActionIcon>
                    ) : (
                      <Button
                        variant="outline"
                        size="xs"
                        color="red.6"
                        rightIcon={<IconTrash />}
                        onClick={() => handleCleanBasket(student.uid, studentFullName)}>
                        Limpar carrinho
                      </Button>
                    )}
                  </Tooltip>
                </>
              )}
            </Group>
            {showcase && institution && (
              <Box mb="sm" p="xs">
                <PictureCard
                  title={institution.displayName}
                  subtitle={`${showcase.educationalLevel}/${showcase.parentGrade}/${showcase.store}/${showcase.cycle}`}
                  picture={institution.logo}
                />
              </Box>
            )}
            <Title order={5} mb="sm" px="xs">
              Produtos
            </Title>
            {lines?.map(
              ({
                discountAmount,
                lineTotal,
                isProtected,
                minimalSellingQuantity,
                options,
                product: productUid,
                quantity,
                type,
                uid
              }) => {
                const product: any = products.find(product => product.uid === productUid)
                const seller = sellers.find(seller => seller.uid === product?.seller)
                return product ? (
                  <Fragment key={uid}>
                    <Divider m={0} p={0} />
                    <Stack
                      key={uid}
                      spacing="xs"
                      p="xs"
                      sx={{ ':hover': { backgroundColor: '#F5F5F5' } }}>
                      <Grid>
                        <Grid.Col span={7} sm={8} md={9}>
                          <PictureCard
                            title={product?.title}
                            subtitle={
                              product?.description || (product?.upc ? `COD. ${product?.upc}` : '')
                            }
                            picture={product?.image?.file}
                            pictureRadius="sm"
                          />
                        </Grid.Col>
                        <Grid.Col span={5} sm={4} md={3}>
                          <Box
                            sx={{
                              alignSelf: 'flex-end',
                              marginRight: isXs ? 0 : '-15px',
                              transform: 'scale(0.8)'
                            }}>
                            <PictureCard
                              title={seller?.displayName}
                              subtitle="Vendido por:"
                              subtitlePosition="before"
                              picture={seller?.logo}
                              pictureRadius="xl"
                              isReverted
                            />
                          </Box>
                        </Grid.Col>
                      </Grid>
                      {options && options.length > 0 && <ProductComplements options={options} />}
                      <Group position="apart">
                        <Text color="blue.5" weight="bold">
                          <CurrencyField value={lineTotal} />
                          {type === 'contract' && '/contrato'}
                          {type === 'tuition' && '/mês'}
                          <Text
                            color="green.5"
                            weight="bold"
                            size="xs"
                            hidden={discountAmount <= 0}>
                            Desconto <CurrencyField value={discountAmount} />
                          </Text>
                        </Text>
                        {quantity ? (
                          !isProtected ? (
                            <QuantityButton
                              lineUid={uid}
                              basketQuantity={quantity}
                              minimalSellingQuantity={minimalSellingQuantity}
                              type={type}
                              handleMutate={handleMutate}
                            />
                          ) : (
                            <Badge size="lg">qtd. {quantity}</Badge>
                          )
                        ) : null}
                      </Group>
                    </Stack>
                  </Fragment>
                ) : null
              }
            )}
          </Paper>
        )
      })}
    </>
  )
}
